import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { ButtonComponent } from '../../../../../../components/button/button.component';
import { IconComponent } from '../../../../../../components/icon/icon.component';
import { ModalLayoutComponent } from '../../../../../../components/modal-layout/modal-layout.component';
import { AppState } from '../../../../../../store';
import { OrganisationActions } from '../../../../../../store/actions/organisation.actions';

@Component({
  selector: 'app-disable-user-modal',
  standalone: true,
  templateUrl: './disable-user.component.html',
  imports: [CommonModule, ButtonComponent, IconComponent, ModalLayoutComponent],
})
export class DisableUserModalComponent implements OnDestroy {
  @Input()
  public show = false;

  @Input({ required: true })
  public userId = '';

  @Input({ required: true })
  public userName = '';

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  public error = false;
  public view: 'initial' | 'response' = 'initial';
  public pending = false;

  private readonly organisationSubscription = this.store
    .select('organisation')
    .subscribe((organisation) => {
      this.error = !!organisation.error;
      this.pending = organisation.pending;
    });

  constructor(private readonly store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.organisationSubscription?.unsubscribe();
    this.view = 'initial';
  }

  public async removeUser(): Promise<void> {
    this.store.dispatch(
      OrganisationActions.removeUser({ id: encodeURIComponent(this.userId) }),
    );
    this.view = 'response';
  }

  public close(): void {
    this.closed.emit();
    this.ngOnDestroy();
  }
}
