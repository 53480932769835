<app-modal-layout (clickedOutside)="cancelled.emit()" *ngIf="show">
  <div class="rounded-2xl bg-white p-14">
    <ng-container *ngIf="view === 'initial'; else responseView">
      <div
        class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
      >
        <div class="h-16">
          <app-icon icon="disable" />
        </div>
      </div>

      <div class="mt-6 text-center">
        <h2 i18n class="mb-2 text-2xl font-medium">Remove user?</h2>
        <p i18n class="font-light">
          Are you sure you would like to remove user {{ userName }}?
        </p>
      </div>

      <div class="mt-8 flex flex-col">
        <app-button
          (click)="removeUser()"
          type="submit"
          i18n-text
          text="Yes, remove this user"
          classes="w-full"
        />
        <app-button
          i18n-text
          text="Cancel"
          (click)="cancelled.emit()"
          classes="w-full mt-4"
        />
      </div>
    </ng-container>

    <ng-template #responseView>
      <ng-container *ngIf="pending">
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Removing user</h2>
          <p i18n class="mb-8">Please wait.</p>
        </div>
      </ng-container>
      <ng-container *ngIf="!pending && !error">
        <div class="mt-6 text-center">
          <div
            class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
          >
            <div class="h-16">
              <app-icon icon="success" />
            </div>
          </div>
        </div>
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Success!</h2>
          <p i18n class="mb-8">
            User {{ userName }} has been successfully removed.
          </p>
        </div>
        <div class="mt-8 flex flex-col">
          <button (click)="close()" i18n class="text-alpha-blue">
            Close message
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!pending && error">
        <div class="mt-6 text-center">
          <div
            class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
          >
            <div class="h-16">
              <app-icon icon="warning" />
            </div>
          </div>
        </div>
        <div class="mt-6 text-center">
          <h2 i18n class="mb-4 text-3xl font-medium">Error</h2>
          <p i18n class="mb-8">
            The {{ userName }} could not be removed, please try again.
          </p>
        </div>
        <div class="mt-8 flex justify-between">
          <button (click)="close()" i18n class="text-alpha-blue">Cancel</button>
          <button (click)="view = 'initial'" i18n class="text-alpha-blue">
            Retry
          </button>
        </div>
      </ng-container>
    </ng-template>
  </div>
</app-modal-layout>
